import 'babel-core/polyfill'
import 'whatwg-fetch'

import head from 'reveal.js/lib/js/head.min'
import Reveal from 'reveal.js/js/reveal'

import loadPrintCSS from './print-css'

let prefix = '';

document.addEventListener('DOMContentLoaded', (event) => {
	window.Reveal = Reveal; // plugins need that

  // More info https://github.com/hakimel/reveal.js#configuration
  Reveal.initialize({
    controls: true,
    progress: true,
    history: true,
    center: true,
    width: 1216,
    height: 760,
    margin: 0.05,
    slideNumber: 'c',
    viewDistance: 10,
    math: {
      mathjax: `${prefix}mathjax/MathJax.js`,
      config: 'TeX-AMS-MML_SVG&noContrib'
    },
    markdown: {
      tables: true
    },

    // More info https://github.com/hakimel/reveal.js#dependencies
    dependencies: [
      // Cross-browser shim that fully implements classList - https://github.com/eligrey/classList.js/
      { src: `${prefix}lib/js/classList.js`, condition: function() { return !document.body.classList; } },
      { src: `${prefix}plugin/markdown/marked.js` },
      { src: `${prefix}plugin/markdown/markdown.js` },
      // data-src-svg
      { src: `${prefix}plugin/data-src-svg/plugin.js` },
      // Zoom in and out with Alt+click
      { src: `${prefix}plugin/zoom-js/zoom.js`, async: true },
      { src: `${prefix}plugin/notes/notes.js`, async: true },
      //{ src: `${prefix}plugin/highlight/highlight.js`, async: true, callback: function() { hljs.initHighlightingOnLoad(); } },
      // MathJax
      //{ src: `${prefix}plugin/math/math.js`, async: true },
      // MathSvg
      { src: `${prefix}plugin/math-svg/plugin.js`, async: true }
    ]
  });

	loadPrintCSS();

});